import React, { useState, useEffect } from 'react';
import './contest.css';
import Contestadmin1 from './admin/shortfilm'
import Contestadmin2 from './admin/dance'
import Contestadmin3 from './admin/photo'
import Contestadmin4 from './admin/fashion'
import Contestadmin5 from './admin/standup'
import Contestadmin6 from './admin/singing'
import Contestadmin7 from './admin/acting'
import Contestadmin8 from './admin/otherarts'
import Contest1 from './contest_page1'
import Contest1_2023 from './contest_page1_2023'
import Contest2 from './contest_page2'
import Contest3 from './contest_page3'
import Contest4 from './contest_page4'
import Contest5 from './contest_page5'
import Contest6 from './contest_page6'
import Contest7 from './contest_page7'
import Contest8 from './contest_page8'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import danpic from '../../image/ballet.svg'
import shortpic from '../../image/director.svg'
import photopic from '../../image/photography 1.svg'
import fashpic from '../../image/fashion-designer 1.svg'
import select_arrow from '../../image/select_arrow.png'
import selected_arrow_up from '../../image/selected_arrow_up.png'
import mask from '../../image/footer-logo-2 2.svg'
import profile_logo from '../../image/Vector.png'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const ContestGallery = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('Creative Carnival Talent Hunt 2024');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const authStatus = localStorage.getItem('isAuthenticated');
  const uniqueId = localStorage.getItem('uniqueId');
  const [selectedSubitem, setSelectedSubitem] = useState('Short Films Mania 2024-25');
  const [showSublist, setShowSublist] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);

  const handleSubitemClick = (subitem) => {
    setSelectedSubitem(subitem);
    const formattedSubitem = subitem.replace(/\s+/g, '_');
    const params = new URLSearchParams(window.location.search);
    params.set('subitem', formattedSubitem);
    window.history.pushState({}, '', `/contest/?${params.toString()}`);
    };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subitem = params.get('subitem') || 'Short_Films_Mania_2024-25';
    console.log(subitem)
    setSelectedSubitem(subitem.replace(/_/g, ' '));
    if (!params.get('subitem')) {
      window.history.replaceState({}, '', `/contest/?subitem=${subitem}`);
  }
  }, [location]);

  // useEffect(() => {
  //   const formattedSubitem = selectedSubitem.replace(/\s+/g, '_');
  //   window.history.pushState({}, '', `/contest/?subitem=${formattedSubitem}`);
  // }, [selectedSubitem]);

  const toggleSearchResultsSidebar = () => {
    setSearchResultsSidebarOpen(!isSearchResultsSidebarOpen);
  };
  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      setShowSublist(!showSublist);
    } else {
      setSelectedOption(option);
      setShowSublist(true);
    }
  };
  const navigate = useNavigate();


  const handleSignOut = () => {
    localStorage.clear();
  };





  const userName = localStorage.getItem('name');

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);


  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false); // Close navbar if sidebar is opened
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      fetch('  https://backend.clicktalksnow.com/search_bar/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ search_text: query })
      })
        .then(response => response.json())
        .then(data => {

          setSearchResults(data.Names);
          setSearchResultsSidebarOpen(data.Names.length > 0); // Assuming the API directly returns the list of names

        })
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
      setSearchResultsSidebarOpen(false); // Clear results if the query is cleared
    }
  };

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false); // Close sidebar if navbar is opened
    }
  };



  return (
    <div className="app-container">
      <div className="navbar">
        <div className="navbar-menu-icon" onClick={toggleSidebar}>
          &#9776;
        </div>
        <div className="navbar-logo1">
          <img src={mask}></img>
          <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
        <div className='searching'>
          {isAuthenticated && (
            <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>)}
        </div>
        <div className="navbar-links">
          {isAuthenticated && (
            <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
          )}

          <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
          <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

          {isAuthenticated && userName ? (
            <>
              <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
              <Link to="/" onClick={handleSignOut}>Sign Out</Link>
            </>
          ) : (
            <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
          )}



        </div>
        <div className="navbar-menu" onClick={toggleNavbar}>
          &#x22EE; {/* Three dots icon for navbar */}
        </div>
        {isMobileNavbarOpen && (
          <div className="mobile-navbar-links">
             <Link to='/'>Home</Link>
            {isAuthenticated && (
              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
            )}

            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}

          </div>
        )}
      </div>

      <div className="container">
        <div className={`sidebar ${isMobileSidebarOpen ? 'open' : ''}`}>
          <h2>Contest gallery</h2>
          <ul>
          <li id='border_try' className={`${selectedOption === 'Short Films' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={shortpic} alt="Short Films" /></span>
                  <span>Short Films</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Short Films' ? 'active' : ''}
                    onClick={() => handleOptionClick('Short Films')}
                    src={showSublist && selectedOption === 'Short Films' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Short Films' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Short Films' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Short Films Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Short Films Mania 2024-25')}
                    >
                      Short Films Talent Hunt 2024-25
                    </li>
                    <li
                      className={selectedSubitem === 'Short Films Mania 2023' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Short Films Mania 2023')}
                    >
                      Short Films Talent Hunt 2023
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Shortfilm Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Shortfilm Video')}
                    >
                      Upload Shortfilm Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>
            <li id='border_try' className={`${selectedOption === 'Dance' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={danpic} alt="Dance" /></span>
                  <span>Dance</span>
                </div>
                <div className='drop_down'>

                  <img
                    className={selectedOption === 'Dance' ? 'active' : ''}
                    onClick={() => handleOptionClick('Dance')}
                    src={showSublist && selectedOption === 'Dance' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Dance' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Dance' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Dance Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Dance Mania 2024-25')}
                    >
                      Dance Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Dance Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Dance Video')}
                    >
                      Upload Dance Video
                    </li>)}
                  </ul>
                )}
              </div>

            </li>
           

           
            <div className='line'></div>

            <li id='border_try' className={`${selectedOption === 'Fashion' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={fashpic} alt="Fashion" /></span>
                  <span>Fashion</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Fashion' ? 'active' : ''}
                    onClick={() => handleOptionClick('Fashion')}
                    src={showSublist && selectedOption === 'Fashion' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Fashion' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Fashion' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Fashion Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Fashion Mania 2024-25')}
                    >
                      Fashion Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Fashion Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Fashion Video')}
                    >
                      Upload Fashion Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>

            <li id='border_try' className={`${selectedOption === 'Photography' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={photopic} alt="Photography" /></span>
                  <span>Photography</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Photography' ? 'active' : ''}
                    onClick={() => handleOptionClick('Photography')}
                    src={showSublist && selectedOption === 'Photography' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Photography' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Photography' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Photography Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Photography Mania 2024-25')}
                    >
                      Photography Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Photograph' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Photograph')}
                    >
                      Upload Photograph
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>

            <li id='border_try' className={`${selectedOption === 'StandUp Comedy' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={photopic} alt="StandUp Comedy" /></span>
                  <span>StandUp Comedy</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'StandUp Comedy' ? 'active' : ''}
                    onClick={() => handleOptionClick('StandUp Comedy')}
                    src={showSublist && selectedOption === 'StandUp Comedy' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'StandUp Comedy' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'StandUp Comedy' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'StandUp Comedy Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('StandUp Comedy Mania 2024-25')}
                    >
                      StandUp Comedy Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload StandUp Comedy Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload StandUp Comedy Video')}
                    >
                      Upload StandUp Comedy Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>

            <li id='border_try' className={`${selectedOption === 'Singing' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={photopic} alt="Singing" /></span>
                  <span>Singing</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Singing' ? 'active' : ''}
                    onClick={() => handleOptionClick('Singing')}
                    src={showSublist && selectedOption === 'Singing' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Singing' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Singing' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Singing Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Singing Mania 2024-25')}
                    >
                      Singing Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Singing Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Singing Video')}
                    >
                      Upload Singing Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>

            <li id='border_try' className={`${selectedOption === 'Acting' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={photopic} alt="Acting" /></span>
                  <span>Acting</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Acting' ? 'active' : ''}
                    onClick={() => handleOptionClick('Acting')}
                    src={showSublist && selectedOption === 'Acting' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Acting' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Acting' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Acting Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Acting Mania 2024-25')}
                    >
                      Acting Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Acting Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Acting Video')}
                    >
                      Upload Acting Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>
            
            <li id='border_try' className={`${selectedOption === 'Other arts' && showSublist ? 'active-background' : ''}`}>
              <div className='logo-name'>
                <div className='logo_name_2'>
                  <span><img src={photopic} alt="Other arts" /></span>
                  <span>Other arts</span>
                </div>
                <div className='drop_down'>
                  <img
                    className={selectedOption === 'Other arts' ? 'active' : ''}
                    onClick={() => handleOptionClick('Other arts')}
                    src={showSublist && selectedOption === 'Other arts' ? selected_arrow_up : select_arrow}
                    alt="Arrow"
                  />
                </div>
              </div>
              {showSublist && selectedOption === 'Other arts' && (<div className='inside_line'></div>)}
              <div className='align_sublist'>
                {showSublist && selectedOption === 'Other arts' && (
                  <ul>
                    <li
                      className={selectedSubitem === 'Other arts Mania 2024-25' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Other arts Mania 2024-25')}
                    >
                      Other arts Talent Hunt 2024-25
                    </li>
                    {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
                    <li
                      className={selectedSubitem === 'Upload Other arts Video' ? 'selected-subitem' : ''}
                      onClick={() => handleSubitemClick('Upload Other arts Video')}
                    >
                      Upload Other arts Video
                    </li>)}
                  </ul>
                )}
              </div>
            </li>
            <div className='line'></div>

           
           
            {/* {uniqueId === '0f5e68a0_19a1_4213_aff2_cee7743a896c' && (
              <li id='border_try' className={selectedOption === 'Admin' ? 'active' : ''} onClick={() => handleOptionClick('Admin')}>
                Admin
              </li>
            )} */}
          </ul>
        </div>
        <div className="main-content">
          {selectedSubitem === 'Short Films Mania 2024-25' && (<Contest1 />)}
          {selectedSubitem === 'Short Films Mania 2023' && (<Contest1_2023 />)}
          {selectedSubitem === 'Dance Mania 2024-25' && (<Contest2 />)}
          {selectedSubitem === 'Photography Mania 2024-25' && (<Contest3 />)}
          {selectedSubitem === 'Fashion Mania 2024-25' && (<Contest4 />)}
          {selectedSubitem === 'StandUp Comedy Mania 2024-25' && (<Contest5 />)}
          {selectedSubitem === 'Singing Mania 2024-25' && (<Contest6 />)}
          {selectedSubitem === 'Acting Mania 2024-25' && (<Contest7 />)}
          {selectedSubitem === 'Other arts Mania 2024-25' && (<Contest8 />)}

          {selectedSubitem === 'Upload Shortfilm Video' && (<Contestadmin1 />)}
          {selectedSubitem === 'Upload Dance Video' && (<Contestadmin2 />)}
          {selectedSubitem === 'Upload Photograph' && (<Contestadmin3 />)}
          {selectedSubitem === 'Upload Fashion Video' && (<Contestadmin4 />)}
          {selectedSubitem === 'Upload StandUp Comedy Video' && (<Contestadmin5 />)}
          {selectedSubitem === 'Upload Singing Video' && (<Contestadmin6 />)}
          {selectedSubitem === 'Upload Acting Video' && (<Contestadmin7 />)}
          {selectedSubitem === 'Upload Other arts Video' && (<Contestadmin8 />)}
        </div>

        {isSearchResultsSidebarOpen && (
          <div className="search-results-sidebar">
            <button onClick={toggleSearchResultsSidebar}>Close</button>
            {searchResults.map(result => (
              <div className="search-result">
                <Link to={`/userprofile?name=${result}`}>
                  <img src={profile_logo} alt="Profile Logo" className="profile-logo" />
                  <span className="profile-name">{result}</span>
                </Link>
              </div>
            ))}
          </div>
        )}

      </div>
    </div>
  );
}

export default ContestGallery;
